<template>
  <div
    class="topbar-wrapper"
    :class="['bg-color--' + backgroundColor]"
    :style="{...foregroundColorCss, ...bgHexColor}"
  >
    <Banner
      v-if="appearance === 'ticker'"
      :desktop-text="bannerTextDesktop"
      :mobile-text="bannerTextMobile"
      :foreground-color="foregroundColor"
      :foreground-color-hex="foregroundColorHex"
      :background-color="backgroundColor"
      :background-color-hex="backgroundColorHex"
      :letter-width="6"
    />

    <div v-if="appearance === 'fade'" class="topbar-text">
      <transition name="fade" mode="out-in">
        <template v-for="(text, index) in topbarTexts">
          <div v-if="showMessage === index" :key="index">
            <div
              v-if="text.mobileText && text.mobileText.html"
              class="mobile-text show-on-mobile"
              v-html="nl2br(text.mobileText.html)"
            />
            <div
              v-if="text.desktopText && text.desktopText.html"
              class="desktop-text show-on-desktop"
              v-html="nl2br(text.desktopText.html)"
            />
          </div>
        </template>
      </transition>
    </div>
  </div>
</template>

<script>
import { color } from '@made-people/centra-storyblok-nuxt-shared/lib/util/ColorHexCode'

import Banner from '@/components/banner/Banner.vue'

export default {
  name: 'Topbar',
  components: {
    Banner,
  },
  props: {
    foregroundColor: {
      type: String,
      required: false,
      default: () => '#ffffff',
    },
    foregroundColorHex: {
      type: String,
      required: false,
      default: '',
    },
    backgroundColor: {
      type: String,
      required: false,
      default: () => 'blue',
    },
    backgroundColorHex: {
      type: String,
      required: false,
      default: '',
    },
    topbarTexts: {
      type: Array,
      required: false,
      default: () => [],
    },
    appearance: {
      type: String,
      required: false,
      default: 'ticker',
    },
  },
  data() {
    return {
      showMessage: 0,
    }
  },
  computed: {
    foregroundColorCss() {
      return {
        '--foreground-color': color(
          this.foregroundColor,
          this.foregroundColorHex
        ),
      }
    },
    bgHexColor(){
      if (this.backgroundColorHex) return { backgroundColor: this.backgroundColorHex }
      return {}
    },
    tickerItems() {
      if (Object.keys(this.topbarTexts).length) {
        const output = []
        for (let i = 0; i < 50; i++) {
          Object.keys(this.topbarTexts).forEach((key) => {
            output.push(this.topbarTexts[key])
          })
        }
        return output
      } else {
        return false
      }
    },
    bannerTextMobile() {
      const res = []
      this.topbarTexts.forEach((text) => {
        res.push(text.mobileText.html)
      })
      return res
    },
    bannerTextDesktop() {
      const res = []
      this.topbarTexts.forEach((text) => {
        res.push(text.desktopText.html)
      })
      return res
    },
  },
  mounted() {
    this.fade(this.topbarTexts.length - 1)
  },
  beforeDestroy() {
    clearInterval(this.fadeInterval)
  },
  methods: {
    nl2br(str) {
      return str.replace(/(?:\r\n|\r|\n)/g, '<br>')
    },
    fade(length) {
      this.fadeInterval = setInterval(() => {
        if (this.showMessage < length) {
          this.showMessage = this.showMessage + 1
        } else {
          this.showMessage = 0
        }
      }, 5000)
    },
  },
}
</script>

<style lang="scss" scoped>
.topbar-wrapper {
  @include p--small;

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--foreground-color);
  height: 3.2rem;
  &.m-hide {
    display: none;
  }
  .topbar-text {
    padding: 0.8rem 1.6rem;
    text-align: center;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;

    div {
      &::v-deep {
        p {
          margin: 0;
        }
        br:last-of-type {
          display: none;
        }
      }
      &.desktop-text {
        display: none;
      }
    }
  }

  /**
   * If its a ticker
   */

  ::v-deep {
    .banner {
      .banner__track__text:nth-child(odd) {
        p {
          @include caps('large');

          margin: 0;
        }

        &:after {
          content: '';
          width: 3.2rem;
          height: 0.2rem;
          background: var(--foreground-color);
          display: inline-block;
          margin-left: 1.6rem;
        }
      }

      .banner__track__text:nth-child(even) {
        p {
          @include label('medium');

          margin: 0;
          text-transform: capitalize;
        }
      }
    }
  }
}
// Tablet (Landscape)
@media screen and (min-width: $tablet-landscape) {
  .topbar-wrapper {
    font-size: 1.4rem;
    position: relative;
    height: 3.2rem;

    justify-content: space-between;
    color: var(--foreground-color);
    &.m-hide {
      display: flex;
    }
    &.d-hide {
      display: none;
    }
    .topbar-text {
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      div {
        &.mobile-text {
          display: none;
        }
        &.desktop-text {
          display: block;
        }
      }
    }
  }
}
// Laptop
@media screen and (min-width: $laptop) {
  .topbar-wrapper {
  }
}
</style>
<style lang="scss">
html {
  --topbar-height: 3.2rem;
}
</style>
